<template>
  <div>
    <v-card flat elevation="0" class="mx-0 px-0">
      <v-toolbar dense elevation="0">
        <div style="max-width: 100px">
          <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
        </div>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>

        <template v-slot:[`item.id`]="{ item }" v-if="!isMobile">
          <div class="d-inline-block">
            <app-tooltip text="Edit">
              <v-btn small color="primary" @click="redirect('HumanResource.Division.Edit', { id: item.id })" icon><v-icon>mdi-pencil</v-icon></v-btn>
            </app-tooltip>
          </div>
          <div class="d-inline-block">
            <app-tooltip text="Delete">
              <v-btn small color="primary" @click="deleteData(item)" icon><v-icon>mdi-delete</v-icon></v-btn>
            </app-tooltip>
          </div>
        </template>
      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 25
      },
      itemsPerPageOption: [25, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "#", value: "id" },
          { text: "Division Code", value: "div_code" },
          { text: "Division Name", value: "div_name" },
        ],
        data: [],
      },
      dialog: null,
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.getData();
    },
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("GET", "division/data");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);

      this.$axios
        .post("division/data", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    async deleteData(item) {
      const post = async () => {
        await this.$axios
          .delete("division/delete/" + item.id)
          .then((res) => {
            var resData = res.data;
            if (resData.status == "success") {
              this.showAlert(resData.status, resData.message);
              this.getData(true);
            }
            this.datatable.loading = false;
          })
          .catch((error) => {
            this.axiosErrorHandler(error);
            this.datatable.loading = false;
          });
      }

      this.showConfirm("Confirm Delete", "Delete \"" + item.div_code + "\"?", post)
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
